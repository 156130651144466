import axios from "axios";
import qs from "querystring";
import { all, fork, put, takeEvery } from "redux-saga/effects";
import {
  invoicesSuccess,
  invoicesReportSuccess,
  invoiceSuccess,
  createInvoiceSuccess,
  updateInvoiceSuccess,
  confirmInvoiceSuccess,
  declineInvoiceSuccess,
  processInvoiceSuccess,
  invoicesError,
  invoicesReportError,
} from "./actions";
import {
  INVOICES_REQUEST,
  INVOICES_REPORT_REQUEST,
  INVOICE_REQUEST,
  CREATE_INVOICE_REQUEST,
  UPDATE_INVOICE_REQUEST,
  CONFIRM_INVOICE_REQUEST,
  DECLINE_INVOICE_REQUEST,
  PROCESS_INVOICE_REQUEST,
} from "./types";
import { API_URL } from "../../config";
import { refundPlayerSuccess } from "../player/actions";
import handleError from "../../helpers/errorHandler";

function* invoicesRequest(action) {
  try {
    const query = qs.stringify(action.payload.query);
    const response = yield axios.post(`${API_URL}/invoices?${query}`, {
      ...action.payload.filters,
      sortBy: action.payload.sortBy,
      desc: action.payload.isDesc,
    });
    yield put(invoicesSuccess(response.data));
  } catch (err) {
    yield put(invoicesError(err));
    handleError(err);
  }
}

function* invoicesReportRequest(action) {
  try {
    const query = qs.stringify(action.payload);
    const response = yield axios.get(`${API_URL}/invoices/report?${query}`);
    yield put(invoicesReportSuccess(response.data));
  } catch (err) {
    yield put(invoicesReportError(err));
    handleError(err);
  }
}

function* invoiceRequest(action) {
  try {
    const response = yield axios.get(
      `${API_URL}/invoices/detail?id=${action.payload.invoiceId}`
    );
    yield put(invoiceSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* createInvoiceRequest(action) {
  console.log("createInvoiceRequest action", action);
  try {
    const response = yield axios.post(
      `${API_URL}/invoices/create`,
      action.payload
    );
    yield put(createInvoiceSuccess(response.data));
    yield put(refundPlayerSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* updateInvoiceRequest(action) {
  try {
    const response = yield axios.post(
      `${API_URL}/invoices/update`,
      action.payload
    );
    yield put(updateInvoiceSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* confirmInvoiceRequest(action) {
  try {
    const response = yield axios.get(
      `${API_URL}/invoices/confirm?id=${action.payload.invoiceId}`
    );
    yield put(confirmInvoiceSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* processInvoiceRequest(action) {
  try {
    const response = yield axios.get(
      `${API_URL}/invoices/process?id=${action.payload.invoiceId}`
    );
    yield put(processInvoiceSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* declineInvoiceRequest(action) {
  try {
    const response = yield axios.post(
      `${API_URL}/invoices/decline`,
      action.payload
    );
    yield put(declineInvoiceSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* watchRequests() {
  yield takeEvery(INVOICES_REQUEST, invoicesRequest);
  yield takeEvery(INVOICE_REQUEST, invoiceRequest);
  yield takeEvery(CREATE_INVOICE_REQUEST, createInvoiceRequest);
  yield takeEvery(UPDATE_INVOICE_REQUEST, updateInvoiceRequest);
  yield takeEvery(CONFIRM_INVOICE_REQUEST, confirmInvoiceRequest);
  yield takeEvery(PROCESS_INVOICE_REQUEST, processInvoiceRequest);
  yield takeEvery(DECLINE_INVOICE_REQUEST, declineInvoiceRequest);
  yield takeEvery(INVOICES_REPORT_REQUEST, invoicesReportRequest);
}

function* invoicesSaga() {
  yield all([fork(watchRequests)]);
}

export default invoicesSaga;
