import * as types from "./types";

export const playersRequest = (payload) => ({
  type: types.PLAYERS_REQUEST,
  payload,
});
export const playersError = (payload) => ({
  type: types.PLAYERS_ERROR,
  payload,
});
export const playersSuccess = (payload) => ({
  type: types.PLAYERS_SUCCESS,
  payload,
});

export const playerRequest = (payload) => ({
  type: types.PLAYER_REQUEST,
  payload,
});
export const playerSuccess = (payload) => ({
  type: types.PLAYER_SUCCESS,
  payload,
});

export const playerHistoryRequest = (payload) => ({
  type: types.PLAYER_HISTORY_REQUEST,
  payload,
});
export const playerHistorySuccess = (payload) => ({
  type: types.PLAYER_HISTORY_SUCCESS,
  payload,
});

export const banPlayerRequest = (payload) => ({
  type: types.BAN_PLAYER_REQUEST,
  payload,
});
export const banPlayerSuccess = (payload) => ({
  type: types.BAN_PLAYER_SUCCESS,
  payload,
});

export const activatePlayerRequest = (payload) => ({
  type: types.ACTIVATE_PLAYER_REQUEST,
  payload,
});
export const activatePlayerSuccess = (payload) => ({
  type: types.ACTIVATE_PLAYER_SUCCESS,
  payload,
});

export const refundPlayerRequest = (payload) => ({
  type: types.REFUND_PLAYER_REQUEST,
  payload,
});
export const refundPlayerSuccess = (payload) => ({
  type: types.REFUND_PLAYER_SUCCESS,
  payload,
});

export const createPlayerRequest = (payload) => ({
  type: types.CREATE_PLAYER_REQUEST,
  payload,
});
export const createPlayerSuccess = (payload) => ({
  type: types.CREATE_PLAYER_SUCCESS,
  payload,
});

export const deletePlayerRequest = (payload) => ({
  type: types.DELETE_PLAYER_REQUEST,
  payload,
});
export const deletePlayerSuccess = (payload) => ({
  type: types.DELETE_PLAYER_SUCCESS,
  payload,
});

export const sendBalanceRequest = (payload) => ({
  type: types.SEND_BALANCE_REQUEST,
  payload,
});
export const sendBalanceSuccess = () => ({ type: types.SEND_BALANCE_SUCCESS });

export const statsRequest = (payload) => ({
  type: types.STATS_REQUEST,
  payload,
});
export const statsSuccess = (payload) => ({
  type: types.STATS_SUCCESS,
  payload,
});

export const setCommentRequest = (payload) => ({
  type: types.SET_COMMENT_REQUEST,
  payload,
});
export const setCommentSuccess = (payload) => ({
  type: types.SET_COMMENT_SUCCESS,
  payload,
});

export const setDirectorRequest = (payload) => ({
  type: types.SET_DIRECTOR_REQUEST,
  payload,
});
export const setDirectorSuccess = (payload) => ({
  type: types.SET_DIRECTOR_SUCCESS,
  payload,
});

export const availableCashbackRequest = (payload) => ({
  type: types.AVAILABLE_CASHBACK_REQUEST,
  payload,
});
export const availableCashbackError = (payload) => ({
  type: types.AVAILABLE_CASHBACK_ERROR,
  payload,
});
export const availableCashbackSuccess = (payload) => ({
  type: types.AVAILABLE_CASHBACK_SUCCESS,
  payload,
});

export const giveCashbackRequest = (payload) => ({
  type: types.GIVE_CASHBACK_REQUEST,
  payload,
});
export const giveCashbackError = (payload) => ({
  type: types.GIVE_CASHBACK_ERROR,
  payload,
});
export const giveCashbackSuccess = (payload) => ({
  type: types.GIVE_CASHBACK_SUCCESS,
  payload,
});

export const cashbackHistoryRequest = (payload) => ({
  type: types.CASHBACK_HISTORY_REQUEST,
  payload,
});
export const cashbackHistoryError = (payload) => ({
  type: types.CASHBACK_HISTORY_ERROR,
  payload,
});
export const cashbackHistorySuccess = (payload) => ({
  type: types.CASHBACK_HISTORY_SUCCESS,
  payload,
});

export const bonusParticipatingRequest = (payload) => ({
  type: types.BONUS_PARTICIPATING_REQUEST,
  payload,
});
export const bonusParticipatingError = (payload) => ({
  type: types.BONUS_PARTICIPATING_ERROR,
  payload,
});
export const bonusParticipatingSuccess = (payload) => ({
  type: types.BONUS_PARTICIPATING_SUCCESS,
  payload,
});

export const notyUserRequest = (payload) => ({
  type: types.NOTY_USER_REQUEST,
  payload,
});
export const notyUserError = (payload) => ({
  type: types.NOTY_USER_ERROR,
  payload,
});
export const notyUserSuccess = (payload) => ({
  type: types.NOTY_USER_SUCCESS,
  payload,
});

export const notyAllUserRequest = (payload) => ({
  type: types.NOTY_ALL_USER_REQUEST,
  payload,
});
export const notyAllUserError = (payload) => ({
  type: types.NOTY_ALL_USER_ERROR,
  payload,
});
export const notyAllUserSuccess = (payload) => ({
  type: types.NOTY_ALL_USER_SUCCESS,
  payload,
});
