export const initialState = {
  players: {
    data: [],
    loading: false,
    totalCount: 0,
  },
  cashback: {
    history: {
      data: [],
      loading: false,
      totalCount: 0,
    },
    available: {
      lastPayoutDate: null,
      lastPayoutAmount: null,
      availableAmount: 0,
      depositAmount: 0,
      withdrawAmount: 0,
      loading: false,
    },
  },
  currentPlayer: null,
  stats: {
    withdrawAmount: 0,
    depositAmount: 0,
  },
};

const playersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "@@player/PLAYERS_REQUEST":
      return {
        ...state,
        players: {
          ...state.players,
          loading: true,
        },
      };
    case "@@player/PLAYERS_ERROR":
      return {
        ...state,
        players: {
          ...state.players,
          loading: false,
        },
      };
    case "@@player/PLAYERS_SUCCESS":
      return {
        ...state,
        players: {
          data: action.payload.data,
          totalCount: action.payload.totalCount,
          loading: false,
        },
      };

    case "@@player/PLAYER_SUCCESS":
      return {
        ...state,
        currentPlayer: action.payload,
      };

    case "@@player/PLAYER_HISTORY_SUCCESS":
      return {
        ...state,
        currentPlayer: {
          ...state.currentPlayer,
          balanceHistory: action.payload.balanceHistory,
        },
      };
    case "@@player/BAN_PLAYER_SUCCESS":
      return {
        ...state,
        currentPlayer: action.payload,
      };

    case "@@player/ACTIVATE_PLAYER_SUCCESS":
      return {
        ...state,
        currentPlayer: action.payload,
      };
    case "@@player/CREATE_PLAYER_SUCCESS":
      const newPlayer = {
        ...action.payload,
        balance: action.payload.balance / 100,
        depositAmount: 0,
      };

      return {
        ...state,
        players: {
          ...state.players,
          data: [newPlayer, ...state.players.data],
        },
      };
    case "@@player/DELETE_PLAYER_SUCCESS":
      return {
        ...state,
        players: {
          ...state.players,
          data: state.players.data.filter(
            (player) => player.id !== action.payload
          ),
          totalCount: state.players.totalCount - 1,
        },
      };
    case "@@player/REFUND_PLAYER_SUCCESS":
      return {
        ...state,
        currentPlayer: {
          ...state.currentPlayer,
          balance: action.payload.balance / 100,
        },
      };
    case "@@player/SET_COMMENT_SUCCESS":
      return {
        ...state,
        currentPlayer: {
          ...state.currentPlayer,
          comment: action.payload.comment,
        },
      };
    case "@@player/ABAILABLE_CASHBACK_REQUEST":
      return {
        ...state,
        cashback: {
          ...state.cashback,
          available: {
            ...state.cashback.available,
            loading: true,
          },
        },
      };
    case "@@player/ABAILABLE_CASHBACK_ERROR":
      return {
        ...state,
        cashback: {
          ...state.cashback,
          available: {
            ...state.cashback.available,
            loading: false,
          },
        },
      };
    case "@@player/AVAILABLE_CASHBACK_SUCCESS":
      return {
        ...state,
        cashback: {
          ...state.cashback,
          available: {
            ...action.payload,
            loading: false,
          },
        },
      };
    case "@@player/CASHBACK_HISTORY_REQUEST":
      return {
        ...state,
        cashback: {
          ...state.cashback,
          history: {
            ...state.cashback.history,
            loading: true,
          },
        },
      };
    case "@@player/CASHBACK_HISTORY_ERROR":
      return {
        ...state,
        cashback: {
          ...state.cashback,
          history: {
            ...state.cashback.history,
            loading: false,
          },
        },
      };
    case "@@player/CASHBACK_HISTORY_SUCCESS":
      return {
        ...state,
        cashback: {
          ...state.cashback,
          history: {
            data: action.payload.data,
            totalCount: action.payload.totalCount,
            loading: false,
          },
        },
      };
    case "@@player/SET_DIRECTOR_SUCCESS":
      return {
        ...state,
        currentPlayer: action.payload,
      };
    case "@@player/STATS_SUCCESS":
      return {
        ...state,
        stats: {
          withdrawAmount: action.payload.withdrawAmount,
          depositAmount: action.payload.depositAmount,
        },
      };

    case "@@player/NOTY_USER_SUCCESS":
      return {
        ...state,
        // currentPlayer: action.payload,
      };

    case "@@player/NOTY_USER_ERROR":
      return {
        ...state,
        // currentPlayer: action.payload,
      };

    default: {
      return state;
    }
  }
};

export default playersReducer;
