import axios from "axios";
import { all, fork, put, takeEvery } from "redux-saga/effects";
import qs from "querystring";
import {
  playersSuccess,
  playerSuccess,
  banPlayerSuccess,
  activatePlayerSuccess,
  refundPlayerSuccess,
  createPlayerSuccess,
  sendBalanceSuccess,
  setCommentSuccess,
  deletePlayerSuccess,
  playerHistorySuccess,
  availableCashbackSuccess,
  giveCashbackSuccess,
  cashbackHistorySuccess,
  playersError,
  bonusParticipatingSuccess,
  setDirectorSuccess,
  statsSuccess,
  // notyUserRequest,
  notyUserError,
  notyUserSuccess,
} from "./actions";
import {
  PLAYERS_REQUEST,
  PLAYER_REQUEST,
  BAN_PLAYER_REQUEST,
  ACTIVATE_PLAYER_REQUEST,
  REFUND_PLAYER_REQUEST,
  CREATE_PLAYER_REQUEST,
  SEND_BALANCE_REQUEST,
  SET_COMMENT_REQUEST,
  DELETE_PLAYER_REQUEST,
  PLAYER_HISTORY_REQUEST,
  AVAILABLE_CASHBACK_REQUEST,
  GIVE_CASHBACK_REQUEST,
  CASHBACK_HISTORY_REQUEST,
  BONUS_PARTICIPATING_REQUEST,
  SET_DIRECTOR_REQUEST,
  STATS_REQUEST,
  NOTY_USER_REQUEST,
  NOTY_ALL_USER_REQUEST,
} from "./types";
import { API_URL } from "../../config";
import handleError from "../../helpers/errorHandler";

function* playersRequest(action) {
  try {
    const query = qs.stringify(action.payload.query);
    const filters = qs.stringify(action.payload.filters);
    const response = yield axios.get(
      `${API_URL}/players?${query}&${filters}&${action.payload.outerFilters}&sortBy=${action.payload.sortBy}&desc=${action.payload.isDesc}`
    );
    yield put(playersSuccess(response.data));
  } catch (err) {
    yield put(playersError(err));
    handleError(err);
  }
}

function* playerRequest(action) {
  try {
    const response = yield axios.get(
      `${API_URL}/players/detail?id=${action.payload.playerId}`
    );
    yield put(playerSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* playerHistoryRequest(action) {
  try {
    const response = yield axios.get(
      `${API_URL}/players/history?id=${action.payload.playerId}`
    );
    yield put(playerHistorySuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* banPlayerRequest(action) {
  try {
    const response = yield axios.get(
      `${API_URL}/players/ban?id=${action.payload.playerId}`
    );
    yield put(banPlayerSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* activatePlayerRequest(action) {
  try {
    const response = yield axios.get(
      `${API_URL}/players/activate?id=${action.payload.playerId}`
    );
    yield put(activatePlayerSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* refundPlayerRequest(action) {
  try {
    const response = yield axios.post(
      `${API_URL}/players/refund`,
      action.payload
    );
    yield put(refundPlayerSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* createPlayerRequest(action) {
  try {
    const response = yield axios.post(
      `${API_URL}/players/create`,
      action.payload
    );
    yield put(createPlayerSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* deletePlayerRequest(action) {
  try {
    const response = yield axios.get(
      `${API_URL}/players/delete?id=${action.payload.playerId}`
    );
    yield put(deletePlayerSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* sendBalanceRequest(action) {
  try {
    const response = yield axios.get(
      `${API_URL}/players/sendbalance?id=${action.payload.playerId}`
    );
    yield put(sendBalanceSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* setCommentRequest(action) {
  try {
    const response = yield axios.post(
      `${API_URL}/players/comment?id=${action.payload.playerId}`,
      { comment: action.payload.comment }
    );
    yield put(setCommentSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* availableCashbackRequest(action) {
  try {
    const response = yield axios.get(
      `${API_URL}/cashback/availableCashback?playerId=${action.payload.playerId}`
    );
    yield put(availableCashbackSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* giveCashbackRequest(action) {
  try {
    const response = yield axios.get(
      `${API_URL}/cashback/giveCashback?playerId=${action.payload.playerId}&own_prcnt=${action.payload.own_prcnt}`
    );
    yield put(giveCashbackSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* cashbackHistoryRequest(action) {
  try {
    const response = yield axios.get(
      `${API_URL}/cashback/history?playerId=${action.payload.query.playerId}&pageIndex=${action.payload.query.page}`
    );
    yield put(cashbackHistorySuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* bonusParticipatingRequest(action) {
  try {
    const response = yield axios.get(
      `${API_URL}/players/setBonusStatus?playerId=${action.payload.playerId}&status=${action.payload.status}`
    );
    yield put(bonusParticipatingSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* setDirectorRequest(action) {
  try {
    const response = yield axios.post(
      `${API_URL}/players/setDirector`,
      action.payload
    );
    yield put(setDirectorSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* statsRequest(action) {
  try {
    const response = yield axios.get(
      `${API_URL}/invoices/getPlayerStatsByDate`,
      { params: action.payload }
    );
    yield put(statsSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* notyUserRequest(action) {
  try {
    console.log("notyUserRequest action", action);
    const response = yield axios.post(`${API_URL}/noty/solo`, action.payload);
    console.log(response);
    const { data } = response;
    if (data.status > 0) {
      if (data.status === 1) {
        alert(`Ошибка отправки: ЗАБЛОКИРОВАЛ БОТА`);
      } else {
        alert(`Ошибка отправки: ${data.message}`);
      }
    } else {
      alert(`Успешно отправлено`);
    }
    // yield put(notyUserSuccess(response.data));
  } catch (err) {
    console.log("err:", err);
    // yield put(notyUserError(err));
    handleError(err);
  }
}
function* notyAllUserRequest(action) {
  try {
    console.log("notyAllUserRequest action", action);
    const response = yield axios.post(`${API_URL}/noty/all`, action.payload);
    console.log(response);
    const { data } = response;
    console.log(data);
    // const { success, error, blocked, other } = data;

    let respons_message = `Успешно отправлено: ${data.success} \n`;
    if (data.blocked > 0) {
      respons_message += `Заблокировано: ${data.blocked} \n`;
    }
    if (data.error > 0) {
      respons_message += `Ошибка отправки: ${data.error} \n`;
    }
    if (data.other > 0) {
      respons_message += `Другие ошибки: ${data.other} \n`;
    }
    alert(respons_message);
    // if (data.status > 0) {
    //   if (data.status === 1) {
    //     alert(`Ошибка отправки: ЗАБЛОКИРОВАЛ БОТА`);
    //   } else {
    //     alert(`Ошибка отправки: ${data.message}`);
    //   }
    // } else {
    //   alert(`Успешно отправлено`);
    // }
    // yield put(notyUserSuccess(response.data));
  } catch (err) {
    console.log("err:", err);
    // yield put(notyUserError(err));
    handleError(err);
  }
}

function* watchRequests() {
  yield takeEvery(PLAYERS_REQUEST, playersRequest);
  yield takeEvery(PLAYER_REQUEST, playerRequest);
  yield takeEvery(PLAYER_HISTORY_REQUEST, playerHistoryRequest);
  yield takeEvery(BAN_PLAYER_REQUEST, banPlayerRequest);
  yield takeEvery(ACTIVATE_PLAYER_REQUEST, activatePlayerRequest);
  yield takeEvery(REFUND_PLAYER_REQUEST, refundPlayerRequest);
  yield takeEvery(CREATE_PLAYER_REQUEST, createPlayerRequest);
  yield takeEvery(DELETE_PLAYER_REQUEST, deletePlayerRequest);
  yield takeEvery(SEND_BALANCE_REQUEST, sendBalanceRequest);
  yield takeEvery(SET_COMMENT_REQUEST, setCommentRequest);
  yield takeEvery(AVAILABLE_CASHBACK_REQUEST, availableCashbackRequest);
  yield takeEvery(GIVE_CASHBACK_REQUEST, giveCashbackRequest);
  yield takeEvery(CASHBACK_HISTORY_REQUEST, cashbackHistoryRequest);
  yield takeEvery(BONUS_PARTICIPATING_REQUEST, bonusParticipatingRequest);
  yield takeEvery(SET_DIRECTOR_REQUEST, setDirectorRequest);
  yield takeEvery(STATS_REQUEST, statsRequest);
  yield takeEvery(NOTY_USER_REQUEST, notyUserRequest);
  yield takeEvery(NOTY_ALL_USER_REQUEST, notyAllUserRequest);
}

function* playersSaga() {
  yield all([fork(watchRequests)]);
}

export default playersSaga;
