import axios from "axios";
import { all, fork, put, takeEvery } from "redux-saga/effects";
import {
  createUserSuccess,
  deleteUserSuccess,
  updateUserSuccess,
  usersError,
  usersSuccess,
  userSuccess,
} from "./actions";
import {
  CREATE_USER_REQUEST,
  DELETE_USER_REQUEST,
  UPDATE_USER_REQUEST,
  USERS_REQUEST,
  USER_REQUEST,
} from "./types";
import { API_URL } from "../../config";
import handleError from "../../helpers/errorHandler";

function* usersRequest(action) {
  try {
    const response = yield axios.get(`${API_URL}/user`);
    yield put(usersSuccess(response.data));
  } catch (err) {
    yield put(usersError(err));
    handleError(err);
  }
}

function* userRequest(action) {
  try {
    const response = yield axios.get(
      `${API_URL}/user/findOneById?id=${action.payload.userId}`
    );
    yield put(userSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* createUserRequest(action) {
  try {
    const response = yield axios.post(`${API_URL}/user/create`, action.payload);
    yield put(createUserSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* updateUserRequest(action) {
  try {
    const response = yield axios.post(`${API_URL}/user/update`, action.payload);
    yield put(updateUserSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* deleteUserRequest(action) {
  try {
    const response = yield axios.delete(
      `${API_URL}/user/delete?id=${action.payload.userId}`
    );
    yield put(deleteUserSuccess(response.data));
  } catch (err) {
    handleError(err);
  }
}

function* watchRequests() {
  yield takeEvery(USERS_REQUEST, usersRequest);
  yield takeEvery(USER_REQUEST, userRequest);
  yield takeEvery(CREATE_USER_REQUEST, createUserRequest);
  yield takeEvery(UPDATE_USER_REQUEST, updateUserRequest);
  yield takeEvery(DELETE_USER_REQUEST, deleteUserRequest);
}

function* usersSaga() {
  yield all([fork(watchRequests)]);
}

export default usersSaga;
