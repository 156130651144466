export const PLAYERS_REQUEST = "@@player/PLAYERS_REQUEST";
export const PLAYERS_ERROR = "@@player/PLAYERS_ERROR";
export const PLAYERS_SUCCESS = "@@player/PLAYERS_SUCCESS";

export const PLAYER_REQUEST = "@@player/PLAYER_REQUEST";
export const PLAYER_SUCCESS = "@@player/PLAYER_SUCCESS";

export const PLAYER_HISTORY_REQUEST = "@@player/PLAYER_HISTORY_REQUEST";
export const PLAYER_HISTORY_SUCCESS = "@@player/PLAYER_HISTORY_SUCCESS";

export const BAN_PLAYER_REQUEST = "@@player/BAN_PLAYER_REQUEST";
export const BAN_PLAYER_SUCCESS = "@@player/BAN_PLAYER_SUCCESS";

export const ACTIVATE_PLAYER_REQUEST = "@@player/ACTIVATE_PLAYER_REQUEST";
export const ACTIVATE_PLAYER_SUCCESS = "@@player/ACTIVATE_PLAYER_SUCCESS";

export const REFUND_PLAYER_REQUEST = "@@player/REFUND_PLAYER_REQUEST";
export const REFUND_PLAYER_SUCCESS = "@@player/REFUND_PLAYER_SUCCESS";

export const CREATE_PLAYER_REQUEST = "@@player/CREATE_PLAYER_REQUEST";
export const CREATE_PLAYER_SUCCESS = "@@player/CREATE_PLAYER_SUCCESS";

export const DELETE_PLAYER_REQUEST = "@@player/DELETE_PLAYER_REQUEST";
export const DELETE_PLAYER_SUCCESS = "@@player/DELETE_PLAYER_SUCCESS";

export const SEND_BALANCE_REQUEST = "@@player/SEND_BALANCE_REQUEST";
export const SEND_BALANCE_SUCCESS = "@@player/SEND_BALANCE_SUCCESS";

export const STATS_REQUEST = "@@player/STATS_REQUEST";
export const STATS_SUCCESS = "@@player/STATS_SUCCESS";

export const SET_COMMENT_REQUEST = "@@player/SET_COMMENT_REQUEST";
export const SET_COMMENT_SUCCESS = "@@player/SET_COMMENT_SUCCESS";

export const SET_DIRECTOR_REQUEST = "@@player/SET_DIRECTOR_REQUEST";
export const SET_DIRECTOR_SUCCESS = "@@player/SET_DIRECTOR_SUCCESS";

export const AVAILABLE_CASHBACK_REQUEST = "@@player/AVAILABLE_CASHBACK_REQUEST";
export const AVAILABLE_CASHBACK_ERROR = "@@player/AVAILABLE_CASHBACK_ERROR";
export const AVAILABLE_CASHBACK_SUCCESS = "@@player/AVAILABLE_CASHBACK_SUCCESS";

export const GIVE_CASHBACK_REQUEST = "@@player/GIVE_CASHBACK_REQUEST";
export const GIVE_CASHBACK_ERROR = "@@player/GIVE_CASHBACK_ERROR";
export const GIVE_CASHBACK_SUCCESS = "@@player/GIVE_CASHBACK_SUCCESS";

export const CASHBACK_HISTORY_REQUEST = "@@player/CASHBACK_HISTORY_REQUEST";
export const CASHBACK_HISTORY_ERROR = "@@player/CASHBACK_HISTORY_ERROR";
export const CASHBACK_HISTORY_SUCCESS = "@@player/CASHBACK_HISTORY_SUCCESS";

export const BONUS_PARTICIPATING_REQUEST =
  "@@player/BONUS_PARTICIPATING_REQUEST";
export const BONUS_PARTICIPATING_ERROR = "@@player/BONUS_PARTICIPATING_ERROR";
export const BONUS_PARTICIPATING_SUCCESS =
  "@@player/BONUS_PARTICIPATING_SUCCESS";

export const NOTY_USER_REQUEST = "@@player/NOTY_USER_REQUEST";
export const NOTY_USER_ERROR = "@@player/NOTY_USER_ERROR";
export const NOTY_USER_SUCCESS = "@@player/NOTY_USER_SUCCESS";
export const NOTY_ALL_USER_REQUEST = "@@player/NOTY_ALL_USER_REQUEST";
export const NOTY_ALL_USER_ERROR = "@@player/NOTY_ALL_USER_ERROR";
export const NOTY_ALL_USER_SUCCESS = "@@player/NOTY_ALL_USER_SUCCESS";
